









import Vue from "vue";

//import table from "./table.vue";
import tableWrapper from "@co/OutputTable/tableWrapper.vue";

//import TextArea from "@cm/TextArea/index.vue";
//import SelectForm from "@cm/SelectForm/index.vue";
//import InputColor from "@cm/InputColor/index.vue";
export default Vue.extend({
  components: {
    tableWrapper,
    //TextArea,
    //SelectForm,
    //InputColor,
  },
  computed: {},
  methods: {},
  data() {
    return {};
  },
});
