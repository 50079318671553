
































































































































import Vue from "vue";
//import InputNumber from "@ca/InputNumber/index.vue";
//import LabelText from "@ca/LabelText/index.vue";
import MinusText from "@ca/MinusText/index.vue";
import PlusText from "@ca/PlusText/index.vue";
import EqualText from "@ca/EqualText/index.vue";
import InputWrapper from "@cm/InputWrapper/index.vue";

import {
  GET_MIC_HOKEN_FOR_SELF,
  GET_MIC_REVENUE,
  GET_MIC_REAL_REVENUE,
  GET_MIC_REVENUE_SELF,
  GET_MIC_EXPENSE,
  GET_MIC_EXPENSE_SELF,
  GET_MIC_DIRECTORS_COMPENSATION,
  GET_MIC_PROFIT,
  GET_MIC_PROFIT_SELF,
  GET_MIC_DEDUCTION_SELF,
} from "@/store/MicroCorporation/getters";

import {
  SET_REVENUE,
  SET_REVENUE_SELF,
  SET_EXPENSE,
  SET_EXPENSE_SELF,
  SET_DIRECTORS_COMPENSATION,
  SET_PROFIT,
  SET_PROFIT_SELF,
  SET_DEDUCTION_SELF,
} from "@/store/MicroCorporation/mutations";
//import handleCanvas from "@/mixin/handleCanvas";
import { mapGetters } from "vuex";
export default Vue.extend({
  components: {
    //InputNumber,
    //LabelText,
    PlusText,
    MinusText,
    EqualText,
    InputWrapper,
  },
  computed: {
    setRevenue() {
      return SET_REVENUE;
    },
    setRevenueSelf() {
      return SET_REVENUE_SELF;
    },
    setExpense() {
      return SET_EXPENSE;
    },
    setExpenseSelf() {
      return SET_EXPENSE_SELF;
    },
    setDirectorsCompensation() {
      return SET_DIRECTORS_COMPENSATION;
    },
    setProfit() {
      return SET_PROFIT;
    },
    setProfitSelf() {
      return SET_PROFIT_SELF;
    },
    setDeductionSelf() {
      return SET_DEDUCTION_SELF;
    },

    ...mapGetters({
      getHoeknForSelf: GET_MIC_HOKEN_FOR_SELF,
      getRevenue: GET_MIC_REVENUE,
      getRealRevenue: GET_MIC_REAL_REVENUE,
      getRevenueSelf: GET_MIC_REVENUE_SELF,
      getExpense: GET_MIC_EXPENSE,
      getExpenseSelf: GET_MIC_EXPENSE_SELF,
      getDirectorsCompensation: GET_MIC_DIRECTORS_COMPENSATION,
      getProfit: GET_MIC_PROFIT,
      getProfitSelf: GET_MIC_PROFIT_SELF,
      //setDeductionSelf: SET_DEDUCTION_SELF,
      getDeductionSelf: GET_MIC_DEDUCTION_SELF,
    }),
  },
  props: {
    //label: String,
    //type: String,
    //items: [],
  },
});
