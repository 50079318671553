







import Vue from "vue";
import InputNumber from "@ca/InputNumber/index.vue";
import LabelText from "@ca/LabelText/index.vue";
import { GET_IS_SMART_PHONE } from "@/store/Common/getters";
import { mapGetters } from "vuex";

export default Vue.extend({
  props: {
    widthPerClass: { type: String, default: "lg:w-2/12" },
    label: { type: String, default: "" },
    disableStatus: { type: Boolean, default: false },
    set: {},
    get: {},
  },
  computed: {
    widthPerClass2() {
      return this.getIsSmartPhone
        ? "w_per__input_custom_smartphone"
        : this.widthPerClass;
    },
    getClass() {
      return `w-full ${this.widthPerClass2}`;
    },
    ...mapGetters({
      getIsSmartPhone: GET_IS_SMART_PHONE,
    }),
  },
  components: {
    InputNumber,
    LabelText,
  },
});
