










import Vue from "vue";
import { GET_IS_SMART_PHONE } from "@/store/Common/getters";
import { mapGetters } from "vuex";
//import handleCanvas from "@/mixin/handleCanvas";
export default Vue.extend({
  //mixins: [handleCanvas],
  props: {
    label: { type: String },
  },
  computed: {
    getTextSize() {
      return this.getIsSmartPhone ? "text-xxs" : "text-xs";
    },
    ...mapGetters({
      getIsSmartPhone: GET_IS_SMART_PHONE,
    }),
  },
});
