import { MutationTree } from "vuex";
import { CommonState } from "@/store/types";

export const SET_IS_SMART_PHONE2 = "setIsSmartPhone";
export const SET_IS_SMART_PHONE = `common/${SET_IS_SMART_PHONE2}`;

const mutations: MutationTree<CommonState> = {
  [SET_IS_SMART_PHONE2](state, payload) {
    state.isSmartPhone =
      window.matchMedia &&
      window.matchMedia("(max-device-width: 640px)").matches;

    console.log("state.isSmartPhone:", state.isSmartPhone);
  },
  //https://qiita.com/mzmz__02/items/8a308e768ae83768a9d5
};

export default mutations;
