


























import Vue from "vue";

//import handleCanvas from "@/mixin/handleCanvas";
import handle from "@/mixin/handle";
import { GET_IS_SMART_PHONE } from "@/store/Common/getters";
import { mapGetters } from "vuex";
export default Vue.extend({
  mixins: [handle],
  props: {
    //disabled: { type: Boolean, default: false },
    set: { type: String, default: "" },
    disableStatus: { type: Boolean, default: false },
  },
  computed: {
    disabledStyle() {
      //console.log("disableStatus:", this.disableStatus);
      return this.disableStatus == true ? "disabled-style" : "xx";
    },
    ...mapGetters({
      getIsSmartPhone: GET_IS_SMART_PHONE,
    }),
    //disabledProperty() {
    //  return this.disabled ? "disabled" : "";
    //},
  },
});
