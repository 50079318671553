











import Vue from "vue";

//import handleCanvas from "@/mixin/handleCanvas";
//import handle from "@/mixin/handle";

export default Vue.extend({
  props: {
    model: { type: String, default: "" },
    label: { type: String, default: "" },
  },
  computed: {
    flag: {
      get() {
        return (this as any).$store.getters[`${(this as any).model}/getFlag`];
      },
      set(value) {
        (this as any).$store.commit(`${(this as any).model}/setFlag`, value);
        //this.value = value;
      },
    },
    //getSalaryManFlag() {
    //
    //  return (this as any).$store.getters["salaryman/getFlag"];
    //},
  },
});
