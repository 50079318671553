























import Vue from "vue";
export default Vue.extend({
  components: {},
  props: {
    title: String,
    type: String,
    imgUrl: String,
  },
  methods: {
    rowspan2(type: string) {
      console.log("sumAll this.type:", this.type);
      return type === "sum" ? { rowspan: 2 } : {};
    },
  },
  computed: {
    rowspan() {
      return this.type === "sum" ? { rowspan: 2 } : {};
      //console.log("sumAll this.type:", this.type);
      //return this.type === "sum" ? "rowspan=2" : "";

      //return this.type == "sumAll" ? "rowspan=2" : "";
    },
  },
  data() {
    return {};
  },
});
