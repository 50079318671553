





















































import Vue from "vue";
import { GET_ALL_FLAGS, GET_TAXES } from "@/store/Common/getters";

import { mapGetters } from "vuex";
import rowTitleList from "./rowTitleList.vue";
import colTitle from "./colTitle.vue";
import colItem from "./colItem.vue";
//import rowSubTitle from "./rowSubTitle.vue";
//import TextArea from "@cm/TextArea/index.vue";
//import SelectForm from "@cm/SelectForm/index.vue";
//import InputColor from "@cm/InputColor/index.vue";
export default Vue.extend({
  components: {
    rowTitleList,
    colTitle,
    colItem,
    //rowSubTitle,
    //colSumItem,
    //TextArea,
    //SelectForm,
    //InputColor,
  },
  computed: {
    ...mapGetters({
      getAllFlags: GET_ALL_FLAGS,
      getTaxes: GET_TAXES,
    }),
  },
  methods: {
    getTypeSelf(type) {
      return `${type}Self`;
    },
    getFlag(model) {
      return (this as any).$store.getters[`${model}/getFlag`];
    },
  },

  data() {
    return {
      items: [
        {
          title: "所得税+復興特別所得税",
          type: "incomeTax",
          imgUrl: "bader.jpg",
        },
        {
          title: "社会保険料",
          type: "hoken",
          imgUrl: "piero.jpg",
        },
        {
          title: "国民年金・厚生年金",
          type: "pension",
          imgUrl: "joker.jpeg",
        },
        {
          title: "住民税",
          type: "residenceTax",
          imgUrl: "squid.jpg",
        },
        //{
        //  title: "事業税",
        //  imgUrl:
        //    "https://images.pexels.com/photos/5212324/pexels-photo-5212324.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
        //},
        {
          title: "法人税等",
          type: "corporateTaxesEtc",
          imgUrl: "tanos.webp",
        },
        {
          title: "合計",
          type: "sum",
          imgUrl: "sum.jpg",
        },
        {
          title: "",
          type: "sumAll",
          imgUrl: "",
        },
      ],
    };
  },
});
