








































































































import Vue from "vue";
import CommonForm from "@cm/CommonForm/index.vue";
import SalarymanForm from "@cm/SalarymanForm/index.vue";
import IndividualBusinessForm from "@cm/IndividualBusinessForm/index.vue";
import CorporationForm from "@cm/CorporationForm/index.vue";
import MicroCorporationForm from "@cm/MicroCorporationForm/index.vue";
import modal from "@/mixin/modal";
export default Vue.extend({
  mixins: [modal],
  components: {
    IndividualBusinessForm,
    SalarymanForm,
    CommonForm,
    CorporationForm,
    MicroCorporationForm,
    //TextArea,
    //SelectForm,
    //InputColor,
  },
  methods: {
    getFlag(model) {
      return (this as any).$store.getters[`${model}/getFlag`];
    },
  },
  data() {
    return {
      fontFamilyList: [
        { name: "Nanum Gothic", value: "Nanum Gothic" },
        { name: "Gulim", value: "Gulim" },
        { name: "Arial", value: "Arial" },
        { name: "Helvetica", value: "Helvetica" },
      ],
      fontSizeList: [
        { name: "15", value: "15" },
        { name: "20", value: "20" },
        { name: "25", value: "25" },
        { name: "30", value: "30" },
        { name: "35", value: "35" },
        { name: "40", value: "40" },
        { name: "45", value: "45" },
      ],
      fontWeightList: [
        { name: "Lghter", value: "lighter" },
        { name: "Normal", value: "normal" },
        { name: "Bolder", value: "bolder" },
      ],
      textBorderList: [
        { name: "Transparent", value: "transparent" },
        { name: "Black", value: "black" },
        { name: "White", value: "white" },
      ],
    };
  },
});
