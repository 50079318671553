import Vue from "vue";
export default Vue.extend({
  data() {
    return {};
  },
  computed: {},
  methods: {
    showModal() {
      this.$modal.show("hello-world");
    },
    hideModal() {
      this.$modal.hide("hello-world");
    },
  },
});
