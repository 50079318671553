














































import Vue from "vue";
import MinusText from "@ca/MinusText/index.vue";
import EqualText from "@ca/EqualText/index.vue";
import InputWrapper from "@cm/InputWrapper/index.vue";
import { mapGetters } from "vuex";
import {
  GET_FRE_REVENUE,
  GET_FRE_EXPENSE,
  GET_FRE_DEDUCTION,
  GET_FRE_PROFIT,
} from "@/store/IndividualBusiness/getters";

import {
  SET_REVENUE,
  SET_EXPENSE,
  SET_DEDUCTION,
  SET_PROFIT,
} from "@/store/IndividualBusiness/mutations";

//import handleCanvas from "@/mixin/handleCanvas";
export default Vue.extend({
  components: {
    InputWrapper,

    MinusText,
    EqualText,
  },
  computed: {
    deductionDescription() {
      return "青色申告特別控除: 65万円、基本控除: 48万円、配偶者控除:38万円";
    },
    setRevenue() {
      return SET_REVENUE;
    },
    setExpense() {
      return SET_EXPENSE;
    },
    setDeduction() {
      return SET_DEDUCTION;
    },
    setProfit() {
      return SET_PROFIT;
    },
    ...mapGetters({
      getRevenue: GET_FRE_REVENUE,
      getExpense: GET_FRE_EXPENSE,
      getDeduction: GET_FRE_DEDUCTION,
      getProfit: GET_FRE_PROFIT,
    }),
  },
  methods: {
    //setRevenue(value) {
    //  console.log("setRevenue value2:", value);
    //  (this as any).$store.commit(SET_REVENUE, value);
    //},
  },
  props: {
    label: String,
    type: String,
    items: [],
  },
});
