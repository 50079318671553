












import Vue from "vue";
import modal from "@/mixin/modal";
export default Vue.extend({
  mixins: [modal],
  components: {},
  computed: {},
  props: {},
});
