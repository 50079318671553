




































































































import Vue from "vue";
import {
  ACTION_COMMON_REVENUE,
  ACTION_COMMON_EXPENSE,
} from "@/store/Common/actions";
import {
  GET_COMMON_REVENUE,
  GET_COMMON_EXPENSE,
  GET_ALL_FLAGS,
} from "@/store/Common/getters";

//import InputNumber from "@ca/InputNumber/index.vue";
import InputCheckBoxWithLabel from "@ca/InputCheckBoxWithLabel/index.vue";
//import {
//  GET_REVENUE,
//  GET_EXPENSE,
//  GET_DEDUCTION,
//  GET_PROFIT,
//} from "@/store/Corporation/getters";
//
//import {
//  SET_REVENUE,
//  SET_EXPENSE,
//  SET_DEDUCTION,
//  SET_PROFIT,
//} from "@/store/Corporation/mutations";
//import { GET_FLAG } from "@/store/Salaryman/getters";
import handle from "@/mixin/handle";
import { mapGetters } from "vuex";
export default Vue.extend({
  components: {
    InputCheckBoxWithLabel,
    //InputNumber,
  },
  //mixins: [handleCanvas],
  mixins: [handle],
  props: {
    label: String,
    type: String,
    items: [],
  },
  //...mapGetters({
  //  getFlag: GET_FLAG,
  //  //getExpense: GET_EXPENSE,
  //  //getDeduction: GET_DEDUCTION,
  //  //getProfit: GET_PROFIT,
  //}),
  methods: {
    //...mapActions(ACTION_COMMON_REVENUE, ["update"]),
    //actionCommonRevenue() {
    //  //this.update(this.getCommonRevenue);
    //  //this.payload.id = null
    //},
  },
  computed: {
    //getLabel() {},
    //getModelName() {},
    actionCommonRevenue() {
      return ACTION_COMMON_REVENUE;
    },
    actionCommonExpense() {
      return ACTION_COMMON_EXPENSE;
    },
    salarymanFlag: {
      get() {
        return (this as any).$store.getters["salaryman/getFlag"];
      },
      set(value) {
        (this as any).$store.commit("salaryman/setFlag", value);
        //this.value = value;
      },
    },
    ...mapGetters({
      getCommonRevenue: GET_COMMON_REVENUE,
      getCommonExpense: GET_COMMON_EXPENSE,
      getAllFlags: GET_ALL_FLAGS,
    }),
    //getSalaryManFlag() {
    //
    //  return (this as any).$store.getters["salaryman/getFlag"];
    //},
  },
});
