


























import Vue from "vue";
export default Vue.extend({
  components: {},
  props: {
    self: Boolean,
    colsMode: { type: Boolean, default: false },
    delMode: { type: Boolean, default: false },
    res: Number,
    type: String,
  },
  methods: {
    colspan2(type: string, colsMode: boolean) {
      console.log("@@ colspan2 type:", type);
      return type === "sumAll" && colsMode === true ? { colspan: 2 } : {};
    },
  },
  computed: {
    delTd() {
      return this.type === "sumAll" && this.delMode == true;
    },
    resToString() {
      if (!this.res) return "";
      return Number.isInteger(this.res) ? this.res : this.res.toFixed(2);
    },
  },
  data() {
    return {};
  },
});
