


























































































































































import Vue from "vue";
//import {
//  // Directives
//  VTooltip,
//  //VClosePopper,
//  // Components
//  //Dropdown,
//  //Tooltip,
//  //Menu
//} from "floating-vue";

//import InputNumber from "@ca/InputNumber/index.vue";
//import LabelText from "@ca/LabelText/index.vue";
import MinusText from "@ca/MinusText/index.vue";
import EqualText from "@ca/EqualText/index.vue";
import InputWrapper from "@cm/InputWrapper/index.vue";

import {
  GET_COR_REVENUE,
  GET_COR_EXPENSE,
  GET_COR_DIRECTORS_COMPENSATION,
  GET_COR_PROFIT,
  GET_COR_PROFIT_SELF,
  GET_COR_HOKEN,
  GET_COR_PENSION,
  GET_COR_DEDUCTION,
  GET_COR_SALARY_INCOME_DEDUCTION,
} from "@/store/Corporation/getters";

import {
  SET_REVENUE,
  SET_EXPENSE,
  SET_DIRECTORS_COMPENSATION,
  SET_PROFIT,
} from "@/store/Corporation/mutations";
//import handleCanvas from "@/mixin/handleCanvas";
import { mapGetters } from "vuex";
export default Vue.extend({
  components: {
    //VTooltip,
    //InputNumber,
    //LabelText,
    MinusText,
    EqualText,
    InputWrapper,
  },
  computed: {
    deductionDescription() {
      return "基本控除: 48万円、配偶者控除:38万円";
    },
    setRevenue() {
      return SET_REVENUE;
    },
    setExpense() {
      return SET_EXPENSE;
    },
    setDirectorsCompensation() {
      return SET_DIRECTORS_COMPENSATION;
    },
    setProfit() {
      return SET_PROFIT;
    },
    getHoeknAndPesnion() {
      return this.getHoekn + this.getPension;
    },
    ...mapGetters({
      getRevenue: GET_COR_REVENUE,
      getExpense: GET_COR_EXPENSE,
      getDirectorsCompensation: GET_COR_DIRECTORS_COMPENSATION,
      getProfit: GET_COR_PROFIT,
      getProfitSelf: GET_COR_PROFIT_SELF,
      getHoekn: GET_COR_HOKEN,
      getPension: GET_COR_PENSION,
      getDeduction: GET_COR_DEDUCTION,
      getSalaryIncomeDeduction: GET_COR_SALARY_INCOME_DEDUCTION,
    }),
  },
  props: {
    label: String,
    type: String,
    items: [],
  },
});
