


















import Vue from "vue";
import { GET_IS_SMART_PHONE } from "@/store/Common/getters";
import { mapGetters } from "vuex";
export default Vue.extend({
  //mixins: [handleCanvas],
  props: {
    label: { type: String },
  },
  computed: {
    getPaddingLeftRight() {
      return this.getIsSmartPhone ? "px-1" : "px-3";
    },
    ...mapGetters({
      getIsSmartPhone: GET_IS_SMART_PHONE,
    }),
  },
});
