












//import HeaderWrapper from "@co/HeaderWrapper/index.vue";
import InputForm from "@co/InputForm/index.vue";
//import OutputTable from "@co/OutputTable/index.vue";
import OutputTable from "@co/OutputTable/index.vue";
//import PreviewImage from "@co/PreviewImage/index.vue";
//import MyFooter from "@co/MyFooter/index.vue";
import ModalWrapper from "@cm/ModalWrapper/index.vue";
import Vue from "vue";
//import "floating-vue/dist/style.css";
//import FloatingVue from "floating-vue";
//app.use(FloatingVue);
//Vue.use(FloatingVue);
//import images from "@/utils/images";
//import { cloneDeep } from "lodash";
import { SET_IS_SMART_PHONE } from "@/store/Common/mutations";
export default Vue.extend({
  components: {
    //HeaderWrapper,
    InputForm,
    OutputTable,
    ModalWrapper,
    //PreviewImage,
    //MyFooter,
  },
  methods: {
    handleResize: function () {
      this.$store.commit(SET_IS_SMART_PHONE);
      // resizeのたびにこいつが発火するので、ここでやりたいことをやる
      //this.width = window.innerWidth;
      //this.height = window.innerHeight;
    },
  },
  mounted() {
    this.$store.commit(SET_IS_SMART_PHONE);
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
});
