











































import Vue from "vue";
import InputNumber from "@ca/InputNumber/index.vue";
import LabelText from "@ca/LabelText/index.vue";
import MinusText from "@ca/MinusText/index.vue";
import EqualText from "@ca/EqualText/index.vue";
import { mapGetters } from "vuex";

import {
  SET_YEARLY_INCOME,
  SET_DEDUCTION,
  SET_EARNED_INCOME,
} from "@/store/Salaryman/mutations";
import {
  GET_SAL_YEARLY_INCOME,
  GET_SAL_DEDUCTION,
  GET_SAL_EARNED_INCOME,
} from "@/store/Salaryman/getters";
export default Vue.extend({
  //mixins: [handleCanvas],
  components: {
    InputNumber,
    LabelText,
    MinusText,
    EqualText,
  },
  computed: {
    ...mapGetters({
      getYearlyIncome: GET_SAL_YEARLY_INCOME,
      getDeduction: GET_SAL_DEDUCTION,
      getEarnedIncome: GET_SAL_EARNED_INCOME,
    }),
    setYearlyIncome() {
      return SET_YEARLY_INCOME;
    },
    setDeduction() {
      return SET_DEDUCTION;
    },
    setEarnedIncome() {
      return SET_EARNED_INCOME;
    },
  },
  props: {
    label: String,
    type: String,
    items: [],
  },
});
